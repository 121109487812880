﻿body{
	overflow-x: hidden;
	h1,
	h2,
	h3,
	h4{
		font-family: $primary-font-family;
		text-align: center;
		font-weight: lighter;
	}
}