﻿//
// Home
//

body.home {
	.b-none-sp{
		@include breakpoint(small down) {
			display: none;
		}
	}
	section{
		&.value,
		&.company,
		&.salon{
			margin-bottom: 5em;
		}
	}
	.section-heading{
		font-family: $primary-font-family;
		text-align: center;
		font-weight: lighter;
		span{
			transform:translateY(30px);
			opacity: 0;
			transition:1s;
			display: block;
			&.scroll-in{
				transform:translateY(0);
				opacity: 1;
			}
		}
	}
	h2{
		font-size: rem-calc(40);
		letter-spacing: 5px;
		margin:1em 0;
		box-shadow: -2px 3px 8px $dark-gray;
		padding: 0.6em 0;
		@include breakpoint(small down) {
			// font-size: rem-calc(45);
		}
	}
	//イントロ
	.main-wrap{
		position: relative;
		height: 0;
		padding-top: 50%;
		background: url(#{$imgPath}/home/img_main.jpg)no-repeat;
		background-size: cover;
		@include breakpoint(small down) {
			padding-top: 100%;
			background-position: center center;
		}
		&:before{
			content: "";
			display: block;
			position: absolute;
			left:0;
			top:0;
			background: url(#{$imgPath}/home/bg_main_left-grass.png)no-repeat;
			background-size: 100%;
			width:50%;
			height:100%;
			max-width: 403px;
			opacity: 0;
			transform:translateY(-50px);
			transition:1s;
		}
		&:after{
			content: "";
			display: block;
			position: absolute;
			right:0;
			bottom:0;
			background: url(#{$imgPath}/home/bg_main_right-grass.png)no-repeat;
			background-size: 100%;
			background-position: bottom right;
			width:50%;
			height:100%;
			max-width: 400px;
			opacity: 0;
			transform:translateY(50px);
			transition:1s;
		}
		&.fade-slide{
			&:before{
				opacity: 1;
				transform:translateY(0);
			}
			&:after{
				opacity: 1;
				transform:translateY(0);
			}
		}
		.main__inner{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-family: $secondary-font-family;
			width:100%;
			&:after{
				content: "";
				display: block;
				width:101vw;
				position: absolute;
				height:100%;
				background: url(#{$imgPath}/home/bg_main_line.svg)no-repeat;
				background-size: 100% 100%;
				top:0;
				left:-10px;
				z-index: -1;
				transition:1s;
				opacity: 0;
				transform:translateY(50px);
			}
			&.fade-slide{
				&:after{
					opacity: 1;
					transform:translateY(0);
				}
			}
			// @include breakpoint(small down) {
			// 	width:80%;
			// }
			.main__title{
				// width:80%;
				max-width:650px;
				margin:auto;
				transition:1s;
				opacity: 0;
				transform:translateY(50px);
				&.fade-slide{
					opacity: 1;
					transform:translateY(0);
				}
				@include breakpoint(small down) {
				width:80%;
				}
				img{
					max-width: initial;
					width:100%;
				}
			}
			.sub-title{
				margin-top: .5em;
				transition:1s all 1s;
				opacity: 0;
				transform:translateY(30px);
				font-size: rem-calc(24);
				&.fade-slide{
					opacity: 1;
					transform:translateY(0);
				}
				@include breakpoint(small down) {
					font-size: rem-calc(16);
				}
			}
		}
		.pagedown{
			position: absolute;
			left:0;
			right:0;
			bottom:2em;
			margin:auto;
			text-align: center;
			opacity: 0;
			transform:translateY(15px);
			transition:1s;
			&.fade-slide{
				opacity: 1;
				transform:translateY(0);
			}
			@include breakpoint(small down) {
				bottom:1em;
			}
			a{
				width:30px;
				height:30px;
				display: inline-block;
				// border-right:3px solid $primary-color;
				// border-bottom:3px solid $primary-color;
				// transform:rotate(45deg);
				position: relative;
				@include breakpoint(small down) {
					width:25px;
					height:25px;
				}
				&:after{
					content:"";
					display: block;
					width:2px;
					height:40px;
					background-color: $primary-color;
					position: absolute;
					top:0;
					left:0;
				}
				&:before{
					content:"";
					display: block;
					width:2px;
					height:40px;
					background-color: $primary-color;
					position: absolute;
					top:-20px;
					right:0;
				}
				span{
					width:2px;
					height:5px;
					// border-radius: 50%;
					display: block;
					background-color: $primary-color;
					position: absolute;
					top:0;
					left:0;
					right:0;
					margin:auto;
					animation:dotted 2s infinite;
					@keyframes dotted{
						0%{
							transform:translateY(-35px);
							opacity: 0;
							height:5px;
						}
						100%{
							transform:translateY(-10px);
							opacity: 1;
							height:40px;
						}
					}
				}
			}
		}
	}
	// value
	.value{
		h3{
			margin-bottom: 1em;
			@include breakpoint(small down) {
				padding:0 .2em;
			}
		}
		.value__tab-menu{
			margin-left:0;
			margin-right:0;
			li{
				position: relative;
				padding:0;
				@include breakpoint(medium) {
					width:23%;
				}
				@include breakpoint(small down) {
					width:calc( 50% - 5px);
					flex:initial;
				}
				@include breakpoint(medium) {
					&:not(:last-of-type){
						margin-right: calc( 8% / 3 );
					}
				}
				&.honesty-tab{
					border:1px solid $new-blue;
					@include breakpoint(small down) {
						margin: 0 5px 5px 0;
					}
					a{
						&:after{
							border-right: 3px solid $new-blue;
							border-bottom: 3px solid $new-blue;
						}
					}
				}
				&.passion-tab{
					border:1px solid $new-blue;
					@include breakpoint(small down) {
						margin: 0 0 5px 5px;
					}
					a{
						&:after{
							border-right: 3px solid $new-blue;
							border-bottom: 3px solid $new-blue;
						}
					}
				}
				&.ambition-tab{
					border:1px solid $new-blue;
					@include breakpoint(small down) {
						margin: 5px 5px 0 0;
					}
					a{
						&:after{
							border-right: 3px solid $new-blue;
							border-bottom: 3px solid $new-blue;
						}
					}
				}
				&.teamwork-tab{
					border:1px solid $new-blue;
					@include breakpoint(small down) {
						margin: 5px 0 0 5px;
					}
					a{
						&:after{
							border-right: 3px solid $new-blue;
							border-bottom: 3px solid $new-blue;
						}
					}
				}
				a{
					padding:1em .5em .5em;
					text-decoration: none;
					display: block;
					position: relative;
					p{
						margin-bottom: 0;
					}
					&:after{
						content: "";
						display: block;
						position: absolute;
						z-index: 10000;
						left:0;
						right:0;
						bottom:1em;
						margin:auto;
						width:20px;
						height:20px;
						transform:rotate(45deg);
						transition:.5s;
					}
				}
				.sub-title{
					opacity: 0;
					visibility: hidden;
					transition:.5s;
					font-size: rem-calc(25);
					margin-top: .5em;
					font-family:$secondary-font-family;
					@include breakpoint(small down) {
						font-size: rem-calc(20);
					}
					&.sub-title--one{
						color:$new-blue;
						margin-top: .2em;
					}
					&.sub-title--two{
						color:$new-blue;
					}
					&.sub-title--three{
						color:$new-blue;
					}
					&.sub-title--four{
						color:$new-blue;
					}
				}
				&.honesty-tab{
					&:after{
						border-left:1px solid $new-blue;
						border-right:1px solid $new-blue;
					}
				}
				&.passion-tab{
					&:after{
						border-left:1px solid $new-blue;
						border-right:1px solid $new-blue;
					}
				}
				&.ambition-tab{
					&:after{
						border-left:1px solid $new-blue;
						border-right:1px solid $new-blue;
					}
				}
				&.teamwork-tab{
					&:after{
						border-left:1px solid $new-blue;
						border-right:1px solid $new-blue;
					}
				}
				&.is_active{
					@include breakpoint(medium) {
						&.honesty-tab,
						&.passion-tab,
						&.ambition-tab,
						&.teamwork-tab{
							&:after{
								content:"";
								position: absolute;
								z-index: 10;
								top:100%;
								left:-50%;
								right:-50%;
								margin:auto;
								display: block;
								width:calc( 100% + 2px );
								height:50px;
								background-color: $white;
							}
						}
					}
					.sub-title{
						opacity: 1;
						visibility: visible;
					}
					a{
						&:after{
							transform:translateY(30px)rotate(45deg);
							opacity: 0;
						}
					}
				}
			}
		}
		.value__tab-wrap{
			position: relative;
			&.is_block{
				@include breakpoint(medium) {
					&:before{
						content: "";
						position: absolute;
						z-index: 1000;
						top:1px;
						left:0;
						right:0;
						margin:auto;
						width:calc( 100% - 2px );
						height:50px;
						background-color: #fff;
					}
				}
			}
			.value__tab-wrap__box{
				display: none;
				p{
					padding:3em 0;
					max-width: 700px;
					margin: 0 auto 0;
					@include breakpoint(small down) {
						padding:1em 0.5em;
					}
				}
				&#honesty-tab{
					border:1px solid $new-blue;
					color:$new-blue;
				}
				&#passion-tab{
					border:1px solid $new-blue;
					color:$new-blue;
				}
				&#ambition-tab{
					border:1px solid $new-blue;
					color:$new-blue;
				}
				&#teamwork-tab{
					border:1px solid $new-blue;
					color:$new-blue;
				}
			}
		}
	}
	.company{
		.company__content{
			position: relative;
			height: 0;
			padding-top: 25%;
			background-position: center;
			@include breakpoint(medium) {
				background: url(#{$imgPath}/home/img_company.jpg)no-repeat;
				background-attachment: fixed;
				background-size: cover;
			}
			@include breakpoint(small down) {
				background: url(#{$imgPath}/home/img_company_sp.jpg)no-repeat;
				background-size: cover;
				padding-top: 60%;
			}
			.company-button{
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				margin:auto;
				background-color: $white;
				overflow: hidden;
				width:250px;
				a{
					padding:0.8em 0;
					text-decoration: none;
					display: block;
					font-weight: bold;
					position: relative;
					transition:.5s;
					@include breakpoint(small down) {
						padding:1em 0;
					}
					&:after{
						content: "";
						position: absolute;
						display: block;
						top:0;
						bottom:0;
						right:1em;
						margin:auto;
						width:15px;
						height:15px;
						border-right: 2px solid $primary-color;
						border-bottom: 2px solid $primary-color;
						transform: rotate(-45deg);
						transition:.2s;
					}
					&:before{
						content: "";
						position: absolute;
						z-index: -1;
						left: 50px;
						top: -50%;
						bottom:-50%;
						margin:auto;
						width:0;
						height:250px;
						transform: rotate(25deg);
						background-color: $primary-color;
						transition:.5s;
					}
					&:hover{
						color:$white;
						&:before{
							width:310px;
							left:-50%;
						}
					}
				}
			}
		}
	}
	.salon{
		.salon__bg{
			position: relative;
			height: 0;
			padding-top: 25%;
			background-position: center;
			@include breakpoint(medium) {
				background: url(#{$imgPath}/home/img_salon.jpg)no-repeat;
				background-attachment: fixed;
				background-size: cover;
			}
			@include breakpoint(small down) {
				background: url(#{$imgPath}/home/img_salon_sp.jpg)no-repeat;
				background-size: cover;
				padding-top: 60%;
			}
		}
		.salon__content{
			.salon__wrap{
				padding:0;
				.salon__inner{
					margin:2em 0;
					.salon__inner__content{
						margin-bottom: 4em;
						display: flex;
						@include breakpoint(small down) {
							flex-wrap:wrap;
							margin-bottom: 3em;
						}
						&:nth-of-type(even){
							@include breakpoint(small down) {
								flex-direction: column-reverse;
							}
						}
						.position__block{
							&:first-of-type{
								width:50%;
								border-top: 5px solid $new-blue;
								border-bottom: 5px solid $new-blue;
								@include breakpoint(small down) {
									width:100%;
								}
							}
							&:last-of-type{
								width:50%;
								margin-right: 1%;
								@include breakpoint(small down) {
									width:100%;
									margin:0 auto;
								}
							}
							@include breakpoint(small down) {
								width:100%;
							}
						}
						.voyage__block{
							&:first-of-type{
								width:50%;
								margin-left: 1%;
								@include breakpoint(small down) {
									width:100%;
									margin:0 auto;
								}
							}
							&:last-of-type{
								width:50%;
								border-top: 5px solid $new-blue;
								border-bottom: 5px solid $new-blue;
								@include breakpoint(small down) {
									width:100%;
								}
							}
						}
						.blanc__block{
							&:first-of-type{
								width:50%;
								border-top: 5px solid $new-blue;
								border-bottom: 5px solid $new-blue;
								@include breakpoint(small down) {
									width:100%;
								}
							}
							&:last-of-type{
								width:50%;
								margin-right: 1%;
								@include breakpoint(small down) {
									width:100%;
									margin:0 auto;
								}
							}
							@include breakpoint(small down) {
								width:100%;
							}
						}
						.lick-luck__block{
							&:first-of-type{
								width:50%;
								margin-left: 1%;
								@include breakpoint(small down) {
									width:100%;
									margin:0 auto;
								}
							}
							&:last-of-type{
								width:50%;
								border-top: 5px solid $new-blue;
								border-bottom: 5px solid $new-blue;
								@include breakpoint(small down) {
									width:100%;
								}
							}
							@include breakpoint(small down) {
								width:100%;
							}
						}
						.do-beauty__block{
							&:first-of-type{
								width:50%;
								border-top: 5px solid $new-blue;
								border-bottom: 5px solid $new-blue;
								@include breakpoint(small down) {
									width:100%;
								}
							}
							&:last-of-type{
								width:50%;
								margin-right: 1%;
								@include breakpoint(small down) {
									width:100%;
									margin:0 auto;
								}
							}
							@include breakpoint(small down) {
								width:100%;
							}
						}
						.description-wrap{
							@include breakpoint(small down) {
								max-width:450px;
								margin:auto;
							}
							.salon__text{
								line-height: 2.5;
								margin-bottom: 3em;
								padding: 0 1em; 
								@include breakpoint(small down) {
									margin-bottom: 1em;
								}
								span{
									display: inline-block;
								}
							}
							.sns-box{
								margin:2em 0;
								a{
									display: block;
									position: relative;
									&:after{
										content:"";
										display: block;
										position: absolute;
										top:100%;
										left:0;
										right:0;
										margin:auto;
										width:15px;
										height:15px;
										opacity: 0;
										transform:rotate(45deg) translate(-5px,-5px);
										transition:0.5s;
									}
								}
							}
							&.position__block{
								color:#333333;
								a{
									color:$new-blue;
								}
								.description-wrap__inner{
									padding: 2em .5em;
								}
								.sns-box{
									a{
										&:hover{
											&:after{
												opacity: 1;
												border-bottom: 2px solid $new-blue;
												border-right: 2px solid $new-blue;
												transform:rotate(45deg) translate(2px,2px);
											}
										}
									}
								}
							}
							&.voyage__block{
								color:#333333;
								a{
									color:$new-blue;
								}
								.description-wrap__inner{
									padding: 2em .5em;
								}
								.sns-box{
									a{
										&:hover{
											&:after{
												opacity: 1;
												border-bottom: 2px solid $new-blue;
												border-right: 2px solid $new-blue;
												transform:rotate(45deg) translate(2px,2px);
											}
										}
									}
								}
							}
							&.blanc__block{
								color:#333333;
								a{
									color:$new-blue;
								}
								.description-wrap__inner{
									padding: 2em .5em;
								}
								.sns-box{
									a{
										&:hover{
											&:after{
												opacity: 1;
												border-bottom: 2px solid $new-blue;
												border-right: 2px solid $new-blue;
												transform:rotate(45deg) translate(2px,2px);
											}
										}
									}
								}
							}
							&.lick-luck__block{
								color:#333333;
								a{
									color:$new-blue;
								}
								.description-wrap__inner{
									padding: 2em .5em;
								}
								.sns-box{
									a{
										&:hover{
											&:after{
												opacity: 1;
												border-bottom: 2px solid $new-blue;
												border-right: 2px solid $new-blue;
												transform:rotate(45deg) translate(2px,2px);
											}
										}
									}
								}
							}
							&.do-beauty__block{
								color:#333333;
								a{
									color:$new-blue;
								}
								.description-wrap__inner{
									padding: 2em .5em;
								}
								.sns-box{
									a{
										&:hover{
											&:after{
												opacity: 1;
												border-bottom: 2px solid $new-blue;
												border-right: 2px solid $new-blue;
												transform:rotate(45deg) translate(2px,2px);
											}
										}
									}
								}
							}
							.detail-btn{
								margin:0;
								.link-button{
									width:250px;
									margin:0 auto;
								}
								a{
									display: block;
									text-decoration: none;
									padding:0.6em 0;
								}
								&.position__button{
									.link-button{
										border:1px solid $new-blue;
										a{
											position: relative;
											overflow: hidden;
											&:before{
												content: "";
												position: absolute;
												z-index: -1;
												left: 50px;
												top: -50%;
												bottom:-50%;
												margin:auto;
												width:0;
												height:250px;
												transform: rotate(25deg);
												background-color: $new-blue;
												transition:.5s;
											}
											&:hover{
												color:$white;
												&:before{
													width:330px;
													left:-50%;
												}
											}
										}
									}
								}
								&.voyage__button{
									.link-button{
										border:1px solid $new-blue;
										a{
											position: relative;
											overflow: hidden;
											&:before{
												content: "";
												position: absolute;
												z-index: -1;
												left: 50px;
												top: -50%;
												bottom:-50%;
												margin:auto;
												width:0;
												height:250px;
												transform: rotate(25deg);
												background-color: $new-blue;
												transition:.5s;
											}
											&:hover{
												color:$white;
												&:before{
													width:330px;
													left:-50%;
												}
											}
										}
									}
								}
								&.blanc__button{
									.link-button{
										border:1px solid $new-blue;
										a{
											position: relative;
											overflow: hidden;
											&:before{
												content: "";
												position: absolute;
												z-index: -1;
												left: 50px;
												top: -50%;
												bottom:-50%;
												margin:auto;
												width:0;
												height:250px;
												transform: rotate(25deg);
												background-color: $new-blue;
												transition:.5s;
											}
											&:hover{
												color:$white;
												&:before{
													width:330px;
													left:-50%;
												}
											}
										}
									}
								}
								&.lick-luck__button{
									.link-button{
										border:1px solid $new-blue;
										a{
											position: relative;
											overflow: hidden;
											&:before{
												content: "";
												position: absolute;
												z-index: -1;
												left: 50px;
												top: -50%;
												bottom:-50%;
												margin:auto;
												width:0;
												height:250px;
												transform: rotate(25deg);
												background-color: $new-blue;
												transition:.5s;
											}
											&:hover{
												color:$white;
												&:before{
													width:330px;
													left:-50%;
												}
											}
										}
									}
								}
								&.do-beauty__button{
									.link-button{
										border:1px solid $new-blue;
										a{
											position: relative;
											overflow: hidden;
											&:before{
												content: "";
												position: absolute;
												z-index: -1;
												left: 50px;
												top: -50%;
												bottom:-50%;
												margin:auto;
												width:0;
												height:250px;
												transform: rotate(25deg);
												background-color: $new-blue;
												transition:.5s;
											}
											&:hover{
												color:$white;
												&:before{
													width:330px;
													left:-50%;
												}
											}
										}
									}
								}
							}
						}
						.logo-wrap{
							position: relative;
							padding:0;
							p{
								position: relative;
								&:first-of-type{
									padding:80px 1em;
								}
								// &:last-of-type{
								// 	img{
								// 		width: 100%;
								// 	}
								// }
							}
						}
					}
				}
			}
		}
	}
}
