﻿body.page{
	background: url(#{$imgPath}/page/bg_lower.jpg)repeat-y;
	background-size: 100%;
	h1{
		margin:1em 0 0.4em;
		line-height: .6;
		span{
			border-bottom: 2px solid $medium-gray;
		}
		& + span{
			display: block;
			text-align: center;
			font-size: rem-calc(30);
			margin-top: 1em;
			margin-bottom: 2em;
			@include breakpoint(small down) {
				font-size: rem-calc(20);
			}
		}
	}
	h2{
	    margin-bottom: 1em;
	}
	h3{
		margin-bottom: .5em;
	}
	main{
		@include sub-max-width();
		.page-header__inner{
			margin-bottom: 1.5em;
		}
	}
}
