﻿body.visit{
	.page-header__inner{
		text-align: center;
		h1{
	    line-height: 1.3;
    	border-bottom: 2px solid #cacaca;
    	display: inline-block;
    	span{
    		border-bottom: none;
    	}
		}
	}
	h2{
		font-size: rem-calc(40);
		margin-top: 3rem;
		margin-bottom: 1rem;
		// margin-bottom: 10px;
	}
	section{
		margin-bottom: 5em;
		.section-head{
			transform:translateY(30px);
			opacity: 0;
			transition:1s;
			&.scroll-in{
				transform:translateY(0);
				opacity: 1;
			}
		}
	}
	.intro{
		margin:3.5em auto 0;
		max-width:700px;
	}
	dl{
		display: flex;
		flex-wrap:wrap;
		margin-top: 2em;
		dt{
			width:80%;
			font-weight: normal;
			padding-left: 10%;
			text-align: left;
			&:nth-of-type(even){
				background: $white;
			}
			@include breakpoint(small down) {
				width:100%;
				padding-left: 0;
				text-align: center;
				margin-bottom: 0;
			}
		}
		dd{
			width:20%;
			font-weight: bold;
			&:nth-of-type(even){
				background: $white;
			}
			@include breakpoint(small down) {
				width:100%;
				text-align: center;
				margin-bottom: 1em;
			}
		}
	}
}