body {
	word-wrap: break-word;
}

address {
	font-style: normal;
}

img.expanded {
	@include image-expanded();
}