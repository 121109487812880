.footer {
	background: $primary-color;
	.footer__link-top{
		position: fixed;
		bottom:1em;
		right:1em;
		margin-bottom: 0;
		.button{
			width:3em;
			height:3em;
			background-color: $white;
			border:2px solid $primary-color;
			margin-bottom: 0;
			position: relative;
			transition:.2s;
			&:hover{
				// opacity: .7;
				border-radius: 50%;
			}
			&:after{
				content: "";
				display: block;
				position: absolute;
				top:.75em;
				left:0;
				right:0;
				bottom:0;
				margin:auto;
				transform: rotate(225deg);
				width:1.5em;
				height:1.5em;
				border-right: 2px solid $primary-color;
				border-bottom: 2px solid $primary-color;
			}
		}
	}
	.footer__content{
		color:$white;
		margin-bottom: 0;
		padding:1.5em 0;
	}
}