﻿body.offce{
	h2{
		margin-top: 1em;
		margin-bottom: 10px;
	}
	h3{
		margin-top: 1em;
	}
	section{
		margin-bottom: 5em;
		.section-head{
			transform:translateY(30px);
			opacity: 0;
			transition:1s;
			&.scroll-in{
				transform:translateY(0);
				opacity: 1;
			}
		}
	}
	.intro{
		margin:3.5em auto 0;
		max-width:700px;
	}
	.vision{
		margin:3.5em 0 0;
		.vision-inner{
			width:50%;
			margin:auto;
			@include breakpoint(small down) {
				width:80%;
			}
			img{
				width:100%;
				max-width:initial;
			}
		}
		.sub-title{
			margin-top: 1em;
			font-family: $secondary-font-family;
		}
	}
	.offce-img_wrap{
		display: flex;
		@include breakpoint(small down) {
			flex-wrap: wrap;
		}
		img{
			flex: 33%;
			width: 33%;
			padding: 1em;
			@include breakpoint(small down) {
				flex: 100%;
				width: 100%;
				padding: 1em 0;
			}
		}
	}
	.accounting{
		display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    @include breakpoint(small down) {
    	flex-wrap: wrap;
    }
    li{
    	margin-left: 2em;
    	@include breakpoint(small down) {
    		margin-left: 1.5em;
    	}
    }
	}
}