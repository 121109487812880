﻿body.company{
	section{
		margin-bottom: 5em;
		.section-head{
			transform:translateY(30px);
			opacity: 0;
			transition:1s;
			&.scroll-in{
				transform:translateY(0);
				opacity: 1;
			}
		}
	}
	.intro{
		margin:3.5em auto 0;
		max-width:700px;
	}
	.vision{
		margin:3.5em 0 0;
		.vision-inner{
			width:50%;
			margin:auto;
			@include breakpoint(small down) {
				width:80%;
			}
			img{
				width:100%;
				max-width:initial;
			}
		}
		.sub-title{
			margin-top: 1em;
			font-family: $secondary-font-family;
		}
	}
	.value{
		// h3{
		// 	font-size: rem-calc(30);
		// }
		p{
			margin-bottom: 4em;
			@include breakpoint(small down) {
				margin-bottom: 2em;
			}
		}
	}
	.profile{
		dl{
			display: flex;
			flex-wrap:wrap;
			margin-top: 2em;
			dt{
				width:40%;
				font-weight: normal;
				padding-left: 20%;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					width:100%;
					padding-left: 0;
					text-align: center;
					margin-bottom: 0;
				}
			}
			dd{
				width:60%;
				font-weight: bold;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					width:100%;
					text-align: center;
					margin-bottom: 1em;
				}
			}
		}
	}
	.history{
		dl{
			display: flex;
			flex-wrap:wrap;
			margin-top: 2em;
			@include breakpoint(small down) {
				max-height:400px;
				overflow-y: scroll;
			}
			dt{
				width:40%;
				font-weight: normal;
				padding-left: 10%;
				padding-right: 10%;
				text-align: right;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					width:100%;
					padding-left: 0;
					text-align: center;
					margin-bottom: 0;
				}
			}
			dd{
				width:60%;
				font-weight: bold;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					width:100%;
					text-align: center;
					margin-bottom: 1em;
				}
			}
		}
	}
	.group{
		ul{
			li{
				list-style: none;
				margin-bottom: 1em;
				img{
					margin-left: 20px;
					@include breakpoint(small down) {
						margin-left: 0;
					}
				}
			}
		}
	}
	.partner{
		dl{
			margin-top: 2em;
			p{
				margin-bottom: 0;
				text-align: left;
			}
			dt{
				padding-left: 20%;
				margin-bottom: 0;
				padding-top:1em;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					padding-left: 0;
					text-align: center;
					margin-bottom: 0;
					padding-left: 0;
				}
			}
			dd{
				padding-left: 20%;
				padding-top:0.5em;
				padding-bottom:1em;
				&:nth-of-type(even){
					background: $white;
				}
				@include breakpoint(small down) {
					text-align: center;
					margin-bottom: 1em;
					padding-left: 0.5em;
				}
				a{
					display: flex;
					align-items:center;
					&:before{
						content:"";
						display: inline-block;
						background: url(#{$imgPath}/page/icon_tel.png)no-repeat;
						background-size: 17px 16px;
						width:17px;
						height:16px;
						margin-right: .5em;
					}
				}
			}
		}
	}
}