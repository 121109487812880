//
// Header
//

// Layout
.header {

	&-inner {
		@include main-max-width();
		position: relative;
		// height:65px;
		.nav-bar-wrap{
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			height:62px;
			padding:0 .5em;
			@include breakpoint(small down) {
				// padding:.5em;
			}
		}
		.orverlay{
			display: none;
		}
	}
}

// Logo
.header-logo {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
	@include breakpoint(small down) {
		padding-right: 1em;
	}
	a{
		@include opacity();
	}
}

// menu trigger
.menu-trigger {
	position: relative;
	width:45px;
	height:35px;
	span{
		transition:0.5s;
		&:nth-of-type(1){
			position: absolute;
			transform:translate(0);
			// top:0;
			width:100%;
			height:4px;
			background-color: $primary-color;
		}
		&:nth-of-type(2){
			position: absolute;
			top:0;
			bottom:0;
			left:-50%;
			right:-50%;
			// transform:translate(0,15px);
			height:4px;
			margin:auto;
			width:100%;
			background-color: $primary-color;
			transition:height .5s;
		}
		&:nth-of-type(3){
			position: absolute;
			// bottom:0;
			transform:translate(0,30px);
			width:100%;
			height:4px;
			background-color: $primary-color;
		}
	}
	//ハンバーガーアニメーション戻る時
	&.is-animation{
		span{
			// &:nth-of-type(1){
			// 	animation:0.5s sp-one-initial forwards;
			// 	@keyframes sp-one-initial{
			// 		0%{
			// 			transform:rotate(0);
			// 			bottom:0;
			// 			margin:auto;
			// 			opacity: 1;
			// 		}
			// 		50%{
			// 			opacity: 0;
			// 		}
			// 		75%{
			// 			transform:rotate(0);
			// 		}
			// 		100%{
			// 			opacity: 1;
			// 		}
			// 	}

			// }
			// &:nth-of-type(2){
			// 	animation:0.5s sp-two-initial forwards;
			// 	@keyframes sp-two-initial{
			// 		0%{
			// 			height:0;
			// 		}
			// 		50%{
			// 			height:100%;
			// 		}
			// 		100%{
			// 			height:4px;
			// 		}
			// 	}
			// }
			// &:nth-of-type(3){
			// 	animation:0.5s sp-three-initial forwards;
			// 	@keyframes sp-three-initial{
			// 		0%{
			// 			transform:rotate(0);
			// 			top:0;
			// 			margin:auto;
			// 		}
			// 		50%{
			// 			opacity: 0;
			// 		}
			// 		75%{
			// 			transform:rotate(0);
			// 		}
			// 		100%{
			// 			opacity: 1;
			// 		}
			// 	}
			// }
		}
	}
	//ハンバーガーアニメーション
	&.is-active{
		// position: fixed;
		// top:10px;
		// left:auto;
		// margin:0 0 0 100px;
		span{
			&:nth-of-type(1){
				position: absolute;
				// top:0;
				margin:auto;
				height:4px;
				// transition:1s;
				transform:rotate(135deg)translate(10px,-10px);

				// animation:1s sp-one forwards;
				// @keyframes sp-one{
				// 	0%{
				// 	}
				// 	50%{
				// 		transform:rotate(0)translate(0);
				// 	}
				// 	75%{
				// 		opacity: 0;
				// 	}
				// 	100%{
				// 		transform:rotate(-225deg)translate(10px,-10px);
				// 		// bottom:0;
				// 		opacity: 1;
				// 	}
				// }
			}
			&:nth-of-type(2){
				position: absolute;
				margin:auto;
				height:200%;
				width:140%;
				opacity: 0;
				transform:translateX(-50px);
				transition:opacity 1s ease-in 1s,transform .5s ease-in 1.4s,height .5s;

				// transform:translate(0,15px);
				// animation:1s sp-two forwards;
				// @keyframes sp-two{
				// 	0%{
				// 		height:4px;
				// 		transform:translate(0,15px);
				// 	}
				// 	50%{
				// 		// height:100%;
				// 		// width:100%;
				// 		transform:translate(0,0);

				// 	}
				// 	100%{
				// 		// width:0;
				// 		height:4px;
				// 		transform:translate(0,0);
				// 		transform-origin:top center;
				// 		margin:auto;
				// 	}
				// }
			}
			&:nth-of-type(3){
				position: absolute;
				transform:rotate(225deg)translate(-10px,-10px);
				// top:0;
				margin:auto;
				opacity: 1;
				// @keyframes sp-three{
				// 	0%{
				// 	}
				// 	50%{
				// 		transform:rotate(0);
				// 	}
				// 	75%{
				// 		opacity: 0;
				// 	}
				// 	100%{
				// 		transform:rotate(225deg);
				// 		top:0;
				// 		margin:auto;
				// 		opacity: 1;
				// 	}
				// }
			}
		}
	}
}

// Nav
#header-menu{
	// display: none;
	display: block;
	opacity: 0;
	height: 0;
	// visibility: hidden;

	position: fixed;
	z-index: 10000;
	top:60px;
	left:0;
	width:100%;
	margin:auto;
	background: $primary-color;
	color:$white;
	&.is-animation{
		animation:nav-active-initial 1s forwards;
		@keyframes nav-active-initial{
			0%{
				display: block;
				opacity: 1;
				height: 350px;
				width:100%;
			}
			100%{
				display: none;
				opacity: 0;
				height: 0;
				visibility: hidden;
				width:100%;
			}
		}
	}
	.header-nav {
		list-style: none;
		margin: 0;
		padding: 0;
		height: 0;
		padding:3em 0;
		visibility: hidden;
		opacity: 0;
		transition: 1s;
		> li {
			text-align: center;
			opacity: 0;
			transform:translateY(30px);

			&:not(:last-of-type){
				margin-bottom: 2em;
			}
			&:nth-of-type(2){
				margin-bottom: 3em;
			}
			a {
				text-decoration: none;
				display: block;
				line-height: 1.3;
				span{
					color:$white;
					&:first-of-type{
						font-family: $primary-font-family;
						font-size:rem-calc(65);
						@include breakpoint(small down) {
							font-size: rem-calc(20);
						}
					}
				}
			}
		}
	}
	.head-copy{
		// background-color: $primary-color;
		margin-bottom: 0;
		// padding-bottom: 1em;
		// small{
		// 	opacity: 0;
		// 	// animation:copy-opacity-initial 0.1s forwards;
		// 	// @keyframes copy-opacity-initial{
		// 	// 	0%{
		// 	// 		opacity: 1;
		// 	// 	}
		// 	// 	100%{
		// 	// 		opacity: 0;
		// 	// 	}
		// 	// }
		// }
	}
	&.is-open{
		display: block;
		animation:nav-active 1s forwards;
		@include breakpoint(small down) {
			animation:nav-active_sp 1s forwards;
		}

		@keyframes nav-active{
			0%{
				display: none;
				opacity: 0;
				height: 0;
			}
			100%{
				display: block;
				opacity: 1;
				height: 720px;
			}
		}
		@keyframes nav-active_sp{
			0%{
				display: none;
				opacity: 0;
				height: 0;
			}
			100%{
				display: block;
				opacity: 1;
				height: 480px;
			}
		}
		.header-nav{
			width:100%;
			height:100%;
			opacity: 1;
			visibility: visible;
			li{
				&:first-of-type{
					opacity: 1;
					transform:translateY(0);
					transition:1s all 1s;
				}
				&:nth-of-type(2){
					opacity: 1;
					transform:translateY(0);
					transition:1s all 1.2s;
				}
				&:nth-of-type(3){
					opacity: 1;
					transform:translateY(0);
					transition:1s all 1.4s;
				}
				&:nth-of-type(4){
					opacity: 1;
					transform:translateY(0);
					transition:1s all 1.8s;
				}
				&:last-of-type{
					opacity: 1;
					transform:translateY(0);
					transition:1s all 2s;
				}
			}
		}
		// .head-copy{
		// 	small{
		// 		animation:copy-opacity 2s forwards 1s;
		// 		@keyframes copy-opacity{
		// 			0%{
		// 				opacity: 0;
		// 			}
		// 			100%{
		// 				opacity: 1;
		// 			}
		// 		}
		// 	}
		// }
	}
}
.overlay{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(#000,0.3);
		opacity: 0;
		z-index: 100;
	&.is-active{
		display: block;
		animation:overlay .5s forwards;
		visibility: visible;
		@keyframes overlay{
			0%{
				opacity: 0;
				visibility: hidden;
			}
			100%{
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&:hover{
		cursor: pointer;
	}
}